// import {Page, Text, View, Document, Image, PDFViewer} from '@react-pdf/renderer';
// import {Table, TR, TH, TD} from "@ag-media/react-pdf-table";
import { Table, TD, TH, TR } from "@ag-media/react-pdf-table";
import { Image, Text, View } from "@react-pdf/renderer";
import axios from "axios";
import moment from "moment";

import
  {
    PDFDocument,
    PDFPage,
    PDFSection,
  } from "@equidefi/portals/components/PDF";
import { styles } from "@equidefi/portals/components/PDF.styles";
import { userFullName } from "@equidefi/shared";

import { CurrencyFormatter, NumberFormatter } from "../../../constants/format";

const DATE_FORMAT = "MMM DD, YYYY";

const Logos = ({ url }) => {
  const loadSrc = async () => {
    return axios({
      url,
      responseType: "blob",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((result) => {
        return result.data;
      })
      .catch((e) => {
        console.error("url error", e);
      });
  };

  return (
    <View style={styles.logo}>
      {/* <Image src={loadSrc} style={styles.image} /> */}
      <Image src="/img/logo-high-res.png" style={styles.image} />
    </View>
  );
};

const Title = () => (
  <View style={[{ width: "100%", marginBottom: 20 }, styles.centered]}>
    <Text style={styles.title}>Closing Tranche Summary</Text>
    <Text style={[{ textAlign: "center" }, styles.subtitle]}>
      *(Final Closing Tranche Summary with aggregated closing information
      created upon final closing)*
    </Text>
  </View>
);

const Section = ({ title, children }) => {
  return (
    <View style={styles.section}>
      {title && <Text style={styles.sectionTitle}>{title}</Text>}
      {children}
    </View>
  );
};

export const ClosingCertificatePdf = ({
  tranche,
  summary,
  issuer,
  offering,
  investments,
}) => {
  const quantity =
    investments?.reduce(
      (memo, investment) => memo + Number(investment.quantity),
      0
    ) ?? 0;

  const content = {
    today: moment().format(DATE_FORMAT),
    launchDate: moment(offering?.launch_date).format(DATE_FORMAT),
    closingDate: summary.closing_date
      ? moment(summary.closing_date).format(DATE_FORMAT)
      : "N/A",
    totalNumOfInvestors: investments?.length,
    stock_type: offering?.stock_type,
    quantity: quantity.toLocaleString("en-US"),
    escrowAgent: summary.escrow_agent?.escrow_agent_contact ?? "N/A",
    totalAmount: CurrencyFormatter.format(summary.total_closing_amount ?? 0),
    remainingOfferingAmount: CurrencyFormatter.format(
      offering?.remaining_amount ?? 0
    ),
  };

  return (
    <PDFDocument>
      <PDFPage>
        <View style={[{ fontFamily: "Cerebri Sans" }, styles.wrapper]}>
          <Logos url={issuer.photo_url} />
          <Title />

          <PDFSection title="Offering Summary">
            <Text
              style={[
                { textTransform: "uppercase" },
                styles.semibold,
                styles.section.item,
              ]}
            >
              {issuer?.name}
            </Text>
            <Text style={styles.section.item}>{offering.name}</Text>
            <Text style={styles.section.item}>
              Launch Date: {content.launchDate}
            </Text>
            <Text style={styles.section.item}>
              Closing Date: {content.closingDate}
            </Text>
            <Text style={styles.section.item}>
              Remaining Offering Balance: {content.remainingOfferingAmount}
            </Text>
            <Text style={styles.section.item}>
              Total Closing Amount: {content.totalAmount}
            </Text>
            <Text style={styles.section.item}>
              Total Number of Investors: {content.totalNumOfInvestors}
            </Text>
            <Text style={styles.section.item}>
              Escrow Agent: {content.escrowAgent}
            </Text>
          </PDFSection>

          <PDFSection title="Investment Summary">
            <Text>{content.closingDate}</Text>
          </PDFSection>

          <PDFSection>
            <Table
              weightings={[0.2, 0.15, 0.15, 0.15, 0.1, 0.1, 0.15]}
              style={styles.table}
              tdStyle={{ padding: "2px" }}
            >
              <TH fixed>
                <TD
                  style={[
                    styles.tableCell,
                    styles.tableHeader,
                    { fontSize: 8 },
                  ]}
                >
                  Name
                </TD>
                <TD
                  style={[
                    styles.tableCell,
                    styles.tableHeader,
                    { fontSize: 8, wordBreak: "normal" },
                  ]}
                >
                  State/Province
                </TD>
                <TD
                  style={[
                    styles.tableCell,
                    styles.tableHeader,
                    { fontSize: 8 },
                  ]}
                >
                  Broker
                </TD>
                <TD
                  style={[
                    styles.tableCell,
                    styles.tableHeader,
                    { fontSize: 8 },
                  ]}
                >
                  Amount
                </TD>
                <TD
                  style={[
                    styles.tableCell,
                    styles.tableHeader,
                    { fontSize: 8 },
                  ]}
                >
                  Quantity
                </TD>
                <TD
                  style={[
                    styles.tableCell,
                    styles.tableHeader,
                    { fontSize: 8 },
                  ]}
                >
                  Fund Method
                </TD>
                <TD
                  style={[
                    styles.tableCell,
                    styles.tableHeader,
                    { fontSize: 8 },
                  ]}
                >
                  Funding Date
                </TD>
              </TH>

              {investments?.map((investment) => (
                <TR key={investment.id}>
                  <TD style={[styles.tableCell, { fontSize: 8 }]}>
                    {userFullName(investment.user)}
                  </TD>
                  <TD style={[styles.tableCell, { fontSize: 8 }]}>
                    {investment.user.address_state}
                  </TD>
                  <TD style={[styles.tableCell, { fontSize: 8 }]}>
                    {investment.broker
                      ? userFullName(investment.broker)
                      : "N/A"}
                  </TD>
                  <TD style={[styles.tableCell, { fontSize: 8 }]}>
                    {CurrencyFormatter.format(investment.amount)}
                  </TD>
                  <TD style={[styles.tableCell, { fontSize: 8 }]}>
                    {NumberFormatter.format(investment.quantity)}
                  </TD>
                  <TD style={[styles.tableCell, { fontSize: 8 }]}>
                    {investment.is_manual_payment ? "Manual" : "Stripe"}
                  </TD>
                  <TD style={[styles.tableCell, { fontSize: 8 }]}>
                    {moment(investment.funding_date).format(DATE_FORMAT)}
                  </TD>
                </TR>
              ))}
            </Table>
          </PDFSection>

          <PDFSection>
            <Text>
              Total: <Text style={[styles.bold]}>{content.totalAmount}</Text>
            </Text>
          </PDFSection>

          <PDFSection>
            <Text style={styles.subtitle}>
              Please note that this Closing Tranche Summary is a summary of
              transactions which have closed on the EquiDeFi platform and is
              generated when investments are closed by the issuer. You should
              not explicitly rely on the Closing Tranche Summary in lieu of the
              actual subscription details of investors which may be found in
              their subscription documents. We encourage you to examine
              investors' subscription documents for detailed information about
              investors and their investments.
            </Text>
          </PDFSection>
        </View>
      </PDFPage>
    </PDFDocument>
  );
};
